<template>
  <div>
    <div class="BoxHeight textc bbox plr13">
      <div class="text111 fw700 pt30 mb30 f17">{{examTitle}}</div>
      <div class="result bbox f15 text111">{{$t('testResults')}}：{{score}}分</div>
      <div class="textc mt40 mb15">
        <el-button type="primary"
                   @click="goBack"
                   class="wp80 f16 Btn">{{$t('returnHome')}}</el-button>
      </div>
    </div>
    <!-- 页脚 -->
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from "../../components/footer.vue";
export default {
  components: { Footer },
  data () {
    return {
      examTitle: "",
      score: 100,
    }
  },
  methods: {
    goBack () {
      this.$router.push({ path: "/index" });
    },
  },
  created () {
    this.examTitle = this.$route.query.examTitle;
    this.score = this.$route.query.score;
  }
};
</script>

<style scoped>
.result {
  background: #f5f5f5;
  border-radius: 5px;
  padding: 20px;
}
</style>